import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './Home';
import About from './About';
import Contact from './Contact';
import Projects from './Projects';
import SideBar from './SideBar';
import './App.css';


function App() {
  return (
    <div className="App">
      <div className="app-container">
        <Router>
          <SideBar />

          <div className="content-container">
            <Routes>
              <Route className="title" path="/" element={<Home />} />
              <Route className="title" path="/about" element={<About />} />
              <Route className="title" path="/projects" element={<Projects />} />
              <Route className="title" path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </Router>
      </div>

    </div>
  );
}

export default App;
