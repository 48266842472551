import React from 'react';
import './About.css';
import ButtonMailto from './ButtonMailto';

export default function About() {
    return (
        <div className="About">
            <h1 className="title"> About</h1>
            <div className="sections"> 
            <div className="foto-wrap"> 
            <div className="section-foto">
            </div>
            </div>
            <div className="section section-about">
                <p>Welcome! My name is Sandra and I am a web expert passionate about transforming ideas into beautifully crafted, fully functional websites. Whether it’s designing the look, building it from scratch, or giving your old website a modern refresh, I’m here to help you create something truly exceptional. I’m all about aesthetics and believe that the magic is in the details—because it’s the little things that make a big difference! </p> <p className="section-about2"> Whether you’re dreaming of building your own website, need some guidance, want an expert audit of your current site, or feel like it’s time to give your old website a fresh, contemporary look, I’ve got you covered. I’m here to ensure your website not only looks amazing and up-to-date but also works flawlessly, reflecting your unique vision and connecting with your audience. <br /><br /> Think of me as your go-to person for all things web design, website building, and modernization—together, we’ll create something that’s not just visually stunning but also perfectly tailored to your needs and the times. <br /><br /> Let’s make the internet a prettier, more functional, and modern place, one pixel and one line of code at a time! </p>
            <br/>
            <ButtonMailto className="ButtonMailto" label="Contact me" mailto="mailto:hello@codeandsprinkles.com" />
            </div>
            
            </div>

            
        </div>
    );
}