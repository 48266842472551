import React from 'react';
import bembis1 from './media/bembis1.png';
import bembis2 from './media/bembis2.png';
import zakarauskas1 from './media/zakarauskas1.png';
import zakarauskas2 from './media/zakarauskas2.png';
import tamulaitis1 from './media/tamulaitis1.png';
import tamulaitis3 from './media/tamulaitis3.png';
import gyvensenosveikata1 from './media/gyvensenosveikata1.png';
import gyvensenosveikata2 from './media/gyvensenosveikata2.png';
import gabijaveronika1 from './media/gabijaVeronika1.png';
import gabijaveronika2 from './media/gabijaVeronika2.png';
import "./Projects.css";

export default function Projects() {
    return (
        <div className="Projects">
            <h1 className="title">Projects</h1>
            <div className="section-projects">
                <div className="project-kamchatka" >
                    <img className="project-foto" src={bembis1} alt="Bembis Bembio Motina webpage foto1" />
                    <img className="project-foto" src={bembis2} alt="Bembis Bembio Motina webpage foto2" />
                    <div className="project-description">
                        <p >Website for Bembis  Bembio Motina - uber eclectic record label. Website design and development</p>
                        <p className="project-link">URL <br /><a href="https://www.bembisbembiomotina.lt/" target="_blank" rel="noreferrer">www.bembisbembiomotina.lt</a></p>
                        
                    </div>
                </div>
                <div className="project-zakarauskas" >
                    <img className="project-foto" src={zakarauskas1} alt="A. Zakarauskas webpage foto1" />
                    <img className="project-foto" src={zakarauskas2} alt="A. Zakarauskas webpage foto2" />
                    <div className="project-description">
                        <p >Portfolio website for a painter Andrius Zakarauskas - one of the most notable names and faces of the Lithuanian art scene</p>
                        <p className="project-link">URL <br /><a href="https://www.zakarauskas.com/" target="_blank" rel="noreferrer">www.zakarauskas.com</a></p>
                        
                    </div>
                </div>
                <div className="project-tamulaitis" >
                    <img className="project-foto" src={tamulaitis1} alt="Giedrius Tamulaitis webpage foto1" />
                    <img className="project-foto" src={tamulaitis3} alt="Giedrius Tamulaitis webpage foto2" />
                    <div className="project-description">
                        <p >Collaboration with Giedrius Tamulaitis on his portfolio website. Giedrius Tamulaitis combines music, art and latest technologies to create unique interactive experiences.</p>
                        <p className="project-link">URL <br /><a href="https://www.tamulaitis.lt/" target="_blank" rel="noreferrer">www.tamulaitis.lt</a></p>
                        
                    </div>
                </div>
                <div className="project-gyvensenosveikata" >
                    <img className="project-foto" src={gyvensenosveikata1} alt="gyvensenos sveikata webpage foto1" />
                    <img className="project-foto" src={gyvensenosveikata2} alt="gyvensenos sveikata webpage foto2" />
                    <div className="project-description">
                        <p >Website for a holistic medicine mentor. Website design and development</p>
                        <p className="project-link">URL <br /><a href="https://www.gyvensenosveikata.lt/" target="_blank" rel="noreferrer">www.gyvensenosveikata.lt</a></p>
                        
                    </div>
                </div>
                <div className="project-gabijaveronika" >
                    <img className="project-foto" src={gabijaveronika1} alt="Gabijos Veronikos webpage foto1" />
                    <img className="project-foto" src={gabijaveronika2} alt="Gabijos Veronikos webpage foto2" />
                    <div className="project-description">
                        <p >Website for a lithuanian artist, singer, event coordinators Gabija Veronika. Website design and development</p>
                        <p className="project-link">URL <br /><a href="https://www.gabijaveronika.lt/" target="_blank" rel="noreferrer">www.gabijaveronika.lt</a></p>
                        
                    </div>
                </div>
            </div>
            

        </div>
    );
}