import React from 'react';
import { Link } from 'react-router-dom';
import './SideBar.css';
import CSlogo from './media/CSlogoL.jpg';

export default function SideBar() {
    return (
        <div className="SideBar">
            <div className="logo">
            <Link to="/" className="menu"><img src={CSlogo} alt="Code&sprinkles Logo" className="logoImage" /></Link>
                
            </div>
            <div className="sidebar-line"></div>
            <div className="year"> /2023</div>
        </div>
    );
}