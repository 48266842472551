import React from 'react';
import './Contact.css';
import ButtonMailto from './ButtonMailto';



export default function Contacts (){
    return (
        <div className="Contacts">
            <h1 className="title"> Contact me</h1>
            <div className="section-contact"> 
            <p>Contact me today to discuss sprinkling some digital magic on your website or web application. </p> <p> With a dash of creativity, a sprinkle of innovation, and a whole lot of passion, together we'll create a custom solution that's as unique and delightful as you are!</p>
            <ButtonMailto className="ButtonMailto" label="hello@codeandsprinkles.com" mailto="mailto:hello@codeandsprinkles.com" />
            
        </div>
        <div className="section-info">
        <div className="cs-info">Code & Sprinkles < br/> KVK-nummer 88044432 < br/> btw-id NL004530465B32 < br/>NL37 ABNA 0118 0507 37 < br/>hello@codeandsprinkles.com
         </div>
        <span className="dot"></span>
        </div>
        </div>

        
    );
}