import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

function Navigation() {
    return (
        <nav>
            <Link to="/about" className="menu">About</Link>
            <Link to="/projects" className="menu">Projects</Link>
            <Link to="/contact" className="menu">Contact</Link>
        </nav>
    );
}

export default function Home() {
    return(
        <div className="Home" >
         <div className="text-columns">
            <div className="section-introduction">
            <h2> We sprinkle digital magic on businesses with our custom web development solutions. <br/><br/> Let's add the sprinkles of innovation and creativity to make Your digital presence shine.</h2>
            </div> 
            <div className="section-menu">
                <Navigation />
            </div>
        </div>
        </div>
    );
}

